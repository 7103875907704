<template>
  <section class="dashboard">
    <div v-if="featuredPrice.bts.usd" class="row">
      <div class="col-md-12 grid-margin">

        <div class="row">
          <div class="col-12 col-xl-3 mb-4 mb-xl-0">
            <h4 class="font-weight-normal mb-0">
              <!--XBTS DeFi Wallet-->
              <span class="float-left">
                            <b-dropdown ref="ddcurr" id="dd-currency" :text="curr.toUpperCase()"
                                        variant="outline-secondary" size="sm">
                                <b-dropdown-item @click="setCurrency('usd')">USD</b-dropdown-item>
                                <b-dropdown-item @click="setCurrency('cny')">CNY</b-dropdown-item>
                                <b-dropdown-item @click="setCurrency('rub')">RUB</b-dropdown-item>
                            </b-dropdown>
                        </span>
            </h4>
          </div>

          <div class="col-12 col-xl-9">
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div @click="$router.push('/pools?a=bts')" class="cursor-pointer border-right pr-4 mb-3 mb-xl-0"
                   :class="this.$root.isMobile ? 'col-6' : ''" v-if="featuredPrice.bts[curr]">
                <p class="text-muted"><img :src="'images/assets/bts.png'" class="mr-1" width="18px"/> BTS</p>
                <h4 class="mb-0 font-weight-bold">{{ currSymbols[curr] }} {{ featuredPrice.bts[curr].price }} </h4>
                <!--
                                <p class="mb-0 mt-2 text-white"
                                   :class="featuredPrice.bts[curr].change < 0 ? 'text-warning' : 'text-success'">
                                  <span>{{ featuredPrice.bts[curr].change }}%</span>
                  <span class="ml-1">
                    <small>(24h)</small>
                  </span>
                </p>
                -->

              </div>
              <div @click="$router.push('/pools?a=sth')" class="cursor-pointer pr-4 mb-3 mb-xl-0"
                   :class="this.$root.isMobile ? 'col-6' : 'border-right'">
                <p class="text-muted"><img :src="'images/assets/sth.png'" class="mr-1" width="18px"/> STH</p>
                <h4 class="mb-0 font-weight-bold">{{ currSymbols[curr] }} {{ featuredPrice.sth[curr].price }} </h4>
                <!--
                <p class="mb-0 mt-2 text-white"
                   v-if="featuredPrice.sth && featuredPrice.sth[curr].change !==0"
                   :class="featuredPrice.sth[curr].change < 0 ? 'text-warning' : 'text-success'">
                  <span>{{ featuredPrice.sth[curr].change }}%</span>
                  <span class="ml-1">
                                <small>(24h)</small>
                            </span>
                </p>
                -->
              </div>

              <div @click="$router.push('/pools?a=btc')" class="cursor-pointer border-right pr-4 mb-3 mb-xl-0"
                   :class="this.$root.isMobile ? 'col-6' : ''">
                <p class="text-muted"><img :src="'images/assets/btc.png'" class="mr-1" width="18px"/> BTC</p>
                <h4 class="mb-0 font-weight-bold">{{ currSymbols[curr] }}
                  {{ formatNum(featuredPrice.btc[curr].price, '# ##0.') }} </h4>
                <!--
                                <p class="mb-0 mt-2 text-white"
                                   v-if="featuredPrice.btc && featuredPrice.btc[curr].change !==0"
                                   :class="featuredPrice.btc[curr].change < 0 ? 'text-warning' : 'text-success'">
                                  <span>{{ featuredPrice.btc[curr].change }}%</span>
                                  <span class="ml-1">
                                    <small>(24h)</small>
                                </span>
                                </p>
                -->
                              </div>
                              <!--
                                            <div @click="$router.push('/pools?a=eth')" class="cursor-pointer border-right pr-4 mb-3 mb-xl-0"  :class="this.$root.isMobile ? 'col-6' : ''">
                                              <p class="text-muted"><img :src="'images/assets/eth.png'" class="mr-1" width="18px"/> Ethereum</p>
                                              <h4 class="mb-0 font-weight-bold">{{currSymbols[curr]}} {{formatNum(featuredPrice.eth[curr].price,  '# ##0.')}} </h4>
                                              <p class="mb-0 mt-2 text-white" :class="featuredPrice.eth[curr].change < 0 ? 'text-warning' : 'text-success'">
                                                <span>{{featuredPrice.eth[curr].change}}%</span>
                                                <span class="ml-1">
                                                  <small>(24h)</small>
                                              </span>
                                              </p>
                                            </div>
                              -->


              <div @click="$router.push('/pools?a=hive')" class="cursor-pointer pr-4 mb-3 mb-xl-0"
                   :class="this.$root.isMobile ? 'col-6' : ''">
                <p class="text-muted"><img :src="'images/assets/hive.png'" class="mr-1" width="18px"/> HIVE</p>
                <h4 v-if="featuredPrice.hive" class="mb-0 font-weight-bold">{{ currSymbols[curr] }}
                  {{ featuredPrice.hive[curr].price }}
                </h4>
                <!--
                                <p v-if="featuredPrice.hive && featuredPrice.hive[curr].change !==0" class="mb-0 mt-2 text-white"
                                   :class="featuredPrice.hive[curr].change < 0 ? 'text-warning' : 'text-success'">
                                  <span
                                      v-show="featuredPrice.hive[curr].change > 0">+</span><span>{{
                                    featuredPrice.hive[curr].change
                                  }}%</span>
                                  <span class="ml-1">
                                                <small>(24h)</small>
                                            </span>
                                </p>
                -->
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="!this.$root.isMobile && featuredPrice.bts[curr].volume24h > 0" class="row">
                      <div @click="$router.push('/pools?a=bts')" class="cursor-pointer col-md-3 grid-margin stretch-card">
                        <div class="card">
                          <div v-if="featuredPrice.bts[curr]" class="card-body">
                            <p class="card-title text-md-center text-xl-left">BitShares {{ $t('pool.vol24') }}</p>
                            <div
                                class="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center"
                            >
                              <h4 v-if="featuredPrice.bts[curr].volume24h <= 10000" class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                                {{ currSymbols[curr] }} {{ formatNum(featuredPrice.bts[curr].volume24h, '# ##0.') }}
                              </h4>
                              <h4 v-else :title="this.formatNum(featuredPrice.bts[curr].volume24h, '# ##0.')"
                                  class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                                {{ currSymbols[curr] }} <span
                                  class="text-white">{{ numTransform(featuredPrice.bts[curr].volume24h) }}</span>
                              </h4>
                              <i class=" icon-chart icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div @click="$router.push('/pools?a=sth')" class="cursor-pointer col-md-3 grid-margin stretch-card">
                        <div class="card">
                          <div class="card-body">
                            <p class="card-title text-md-center text-xl-left">SmartHoldem {{ $t('pool.vol24') }}.</p>
                            <div
                                class="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center"
                            >
                              <h4 v-if="featuredPrice.sth[curr].volume24h <= 10000" class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                                {{ currSymbols[curr] }} {{ formatNum(featuredPrice.sth[curr].volume24h, '# ##0.') }}
                              </h4>
                              <h4 v-else :title="this.formatNum(featuredPrice.sth[curr].volume24h, '# ##0.')"
                                  class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                                {{ currSymbols[curr] }} <span
                                  class="text-white">{{ numTransform(featuredPrice.sth[curr].volume24h) }}</span>
                              </h4>
                              <i class="icon-chart icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div @click="$router.push('/pools?a=btc')" class="cursor-pointer col-md-3 grid-margin stretch-card">
                        <div class="card">
                          <div class="card-body">
                            <p class="card-title text-md-center text-xl-left">Bitcoin {{ $t('pool.vol24') }}</p>
                            <div
                                class="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center"
                            >
                              <h4 v-if="featuredPrice.btc[curr].volume24h <= 10000" class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                                {{ currSymbols[curr] }} {{ formatNum(featuredPrice.btc[curr].volume24h, '# ##0.') }}
                              </h4>
                              <h4 v-else :title="this.formatNum(featuredPrice.btc[curr].volume24h, '# ##0.')"
                                  class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                                {{ currSymbols[curr] }} <span
                                  class="text-white">{{ numTransform(featuredPrice.btc[curr].volume24h) }}</span>
                              </h4>
                              <i class=" icon-chart icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div @click="$router.push('/pools?a=eth')" class="cursor-pointer col-md-3 grid-margin stretch-card">
                        <div class="card">
                          <div class="card-body">
                            <p class="card-title text-md-center text-xl-left">Ethereum {{ $t('pool.vol24') }}</p>
                            <div
                                class="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center"
                            >
                              <h4 v-if="featuredPrice.eth[curr].volume24h <= 10000" class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                                {{ currSymbols[curr] }} {{ formatNum(featuredPrice.eth[curr].volume24h, '# ##0.') }}
                              </h4>
                              <h4 v-else :title="this.formatNum(featuredPrice.eth[curr].volume24h, '# ##0.')"
                                  class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                                {{ currSymbols[curr] }} <span
                                  class="text-white">{{ numTransform(featuredPrice.eth[curr].volume24h) }}</span>
                              </h4>
                              <i class=" icon-chart icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 stretch-card grid-margin" :class="this.$root.isMobile ? 'col-6' : ''">
                        <div class="card">
                          <router-link to="/pools"><img class="w-100" src="images/home/1.png"/></router-link>
                        </div>
                      </div>
                      <div v-show="!this.$root.isMobile" class="col-md-4 stretch-card grid-margin">
                        <div class="card">
                          <a target="_blank" href="https://ex.xbts.io/market/XBTSX.USDT_BTS">
                            <img class="w-100" src="images/home/2.png"/>
                          </a>
                        </div>
                      </div>
                      <div class="col-md-4 stretch-card grid-margin" :class="this.$root.isMobile ? 'col-6' : ''">
                        <div class="card">
                          <router-link to="/smartholder"><img class="w-100" src="images/home/3.png"/></router-link>
                        </div>
                      </div>
                      <div class="col-md-4 stretch-card grid-margin" :class="this.$root.isMobile ? 'col-6' : ''">
                        <div class="card">
                          <router-link to="/pools"><img class="w-100" src="images/home/4.png"/></router-link>
                        </div>
                      </div>
                      <div class="col-md-4 stretch-card grid-margin" :class="this.$root.isMobile ? 'col-6' : ''">
                        <div class="card">
                          <router-link to="/pools"><img class="w-100" src="images/home/5.png"/></router-link>
                        </div>
                      </div>
                      <div class="col-md-4 stretch-card grid-margin" :class="this.$root.isMobile ? 'col-6' : ''">
                        <div class="card">
                          <router-link to="/smartholder"><img class="w-100" src="images/home/6.png"/></router-link>
                        </div>
                      </div>
                    </div>
                  </section>
                </template>

                <script>
                import {currSymbols} from '@/config.js';
                //import exchange from '@/components/exchange';

                export default {
                  name: "dashboard",
                  components: {
                    //exchange
                  },
                  data() {
                    return {
                      currSymbols: currSymbols,
                      selected: null,
                      options: [
                        {value: 1, text: 'Please select an option'},
                        {value: 2, text: 'This is First option'},
                        {value: 3, text: 'Selected Option'}
                      ],
                      currency: "usd",
                      loading: true,
                      time1: null,
                      slickOptions: {
                        slidesToShow: 1,
                        prevArrow: this.$refs.prevArrow,
                        nextArrow: this.$refs.nextArrow
                        // Any other options that can be got from plugin documentation
                      },
                    };
                  },
                  computed: {
                    featuredPrice() {
                      return this.$store.getters['xbts/featuredPrice'];
                    },
                    curr() {
                      return this.$store.getters['xbts/currency'] || 'usd';
                    },
                  },
                  methods: {
                    async setCurrency(value) {
                      this.currency = value;
                      await this.$store.dispatch('xbts/setCurrency', value);
                    },
                  },
                  async created() {

                  }
                };
                </script>

                <style>

                </style>
